.contract-type-loading-container {
  z-index: 10;
  position: absolute;
  width: 100%;
  padding-top: 15%;
}

.contract-type-page .table-pagination ul {
  justify-content: center;
  margin-top: 5px;
}

.contract-type-page .page-table .cell {
  padding: 10px;
}

.contract-type-page .row{
  background-color:rgba(0, 0, 0, 0.04);
}

.contract-type-page .row:hover{
  background-color:#fff;
}

.file{
  margin: 0 10px;
  max-width: 50px;
  padding: 0px 10px;
  color:#f50057
}

.modal-delete-contract-type{
  position: absolute;
	top: 50%;
	left: 50%;
	width: 40%;
	transform: translate(-50%, -50%);
	border-radius: 10px;
	box-shadow: 2px 2px 2px #e6e6e6;
	background-color: #FFF;
	padding:15px;
}
.icon{
  margin-left: 10px;
  color:#f50057;
  cursor:pointer
}
