::-webkit-scrollbar
{
  width: 0px;

}
::-webkit-scrollbar-thumb{
	background:rgb(0, 34, 67);
	border-radius: 10px;
}
/* ::-webkit-scrollbar:horizontal{
	height: 8px;
	background: #DDD;
}
::-webkit-scrollbar-thumb:horizontal{
	background:rgb(0, 34, 67);
	border-radius: 10px;
} */
  body {
    margin: 0px;
    padding: 0px;
  }
  img{
    display: block;
    max-width:100%
  }
	.clickable:hover {
		cursor: pointer;
	}
  /** Page Action Bar **/
.page-actionbar-container {
	width:100%;
  display: flex;
  margin: 0.7em;
}
.page-actionbar-item {
	display: flex;
	justify-content: flex-start;
	margin-right: 0.3em;
}
.page-actionbar-item h5{
	font-size:1.3em;
	padding:0
}
.action-btn:hover {
	cursor: pointer;
}

/** Page Filter Bar **/
.page-filter-bar {
	display: flex;
	justify-content: flex-start;
	margin: 0;
}
.page-filter-bar div {
    margin-right: 0.5em;
}
@media (max-width: 660px) {
	.page-filter-bar {
		flex-direction: column;
	}
}

/* Modal container */
.modal-container {
	position: fixed;
	top:50%;
	left:50%;
	width: 65%;
	transform: translate(-50%, -50%);
	border-radius: 10px;
	box-shadow: 2px 2px 2px #e6e6e6;
	background-color: #FFF;
	padding:15px;
	overflow:scroll;
	max-height: 70vh;
	display:block;

}

/* TABLES */

.basic-table .row{
	background-color:rgba(0, 0, 0, 0.04);
	padding:10px 5px;
	}
	.basic-table .row:hover{
		background-color:#fff;
	}
	.basic-table .cell {
		font-size:0.8rem;
		padding:0;
		text-align: center;
	}
	.basic-table-head{
	font-size:.8rem;
	border-radius: 30px;
	background-color:rgb(0, 34, 67);
	}
	.basic-table-head th{
		color:#fff;
	}
	.basic-table-head th:first-child{
		border-top-left-radius: 4px;
	
	}
	.basic-table-head th:last-child{
		border-top-right-radius: 4px;
	
	}

	