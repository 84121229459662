.contractual-parts-container {
  z-index: 99;
  position: absolute;
  background-color: #faf2f7;
  width:100%;
  overflow-y:scroll;
  max-height: 200px;
  padding:10px 15px;
  border-bottom-left-radius: 2%;
  border-bottom-right-radius: 2%;
  border: 1px solid #ddd;
}
.contractual-parts-container::-webkit-scrollbar {
  width:5px;
}
.contractual-parts-container::-webkit-scrollbar{
  width: 5px;
}
.contractual-parts-list{
  list-style: none;
  padding:0;
}
.contractual-parts-list li{
  text-align: center;
  padding:5px 0 8px 0;
  
}

.contractual-parts-list li:hover{
background-color:#e2e2e2;
cursor: pointer;
}

.loading-box{
text-align: center;
}


