/* tab form */

.tab-container{
  margin: 40px 0 40px 0
  }
.tab-buttons{
margin: 5px 0;
display: flex;
justify-content: center;
gap:15px
}

.tab-button{
  outline: none;
  display: flex;
  width:120px;
  justify-content:center;
  align-items: center;
  gap:5px;
  border: 1px solid #777;
  padding:10px;
  background-color: transparent;
  color: #777;
  font-weight:bold;
  font-size: 12px;
  cursor: pointer;
  transition: 0.2s ease-in-out;
}

.tab-button:hover,
.tab-button-active {
  /* border: 3px solid #e33371; */
  outline:none !important;
  color:#e33371;
    border-color:#e33371
}

.split-table{
  width:100%;
  margin: 15px auto;
  background-color:red
}
