.contracts-loading-container {
  z-index: 10;
  position: absolute;
  width: 100%;
  padding-top: 15%;
}

.contracts-page .table-pagination ul {
  justify-content: center;
  margin-top: 5px;
}

.contracts-page .page-table .cell {
  padding: 10px;
}

.contracts-page .row{
  background-color:rgba(0, 0, 0, 0.04);
}

.contracts-page .row:hover{
  background-color:#fff;
}
.download-file{

  margin: 0 auto;
  padding: 0px 10px;
  color:#f50057
}

.horizontalDivider{
  height: 0.1em;
  border-radius: 1em;
  background-color: black;
  width: 100%;
  margin-top: 0.5em;
  margin-bottom: 1.5em;
}

.hover-message {
  position: absolute;
  bottom: 100%;
  left: 0;
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 5px;
  border-radius: 3px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1;
  font-size: 12px;
  color: #333;
  margin-top: 5px;
}
.pending-icon{
  width: 20px;
  height: 20px;
}

.sign-button {
  padding: 5px 6px;
  font-size: 11px;
  border-radius: 5px;
  border: 2px solid #f50057;
  color:#f50057;
  letter-spacing: .725px;
  font-weight: bold;
  transition: .5s;
}

.sign-button:hover {
  border-color: #f50057;
  background-color: #f50057;
  color:#eee;
  outline: none !important;
}

.sign-button:focus {
  outline: none !important;
}

.error-icon{
  color:red
}

.tabContainer{
  display: flex;
  align-items: center;
  justify-content: center;
  gap:15px
}