.header{
  background-color: rgb(0, 34, 67);
  max-width:100%;
  padding: 15px;
  display: flex;
  align-items: center;
}
img.logo{
  width:50px;
}
.menu{
  margin-left: 50px
}
.menu-item{
  color:#fff;
  font-size:.9rem;
  margin-right:15px;
  font-weight: 500;
  text-decoration:none;
  font-family: roboto;
  padding:5px;
  transition: 0.2s;
}
.menu-item:hover{
  color:#ccc
}
.title{
  margin-top:5px;
  margin-left: 0.5em;
  margin-bottom:0.5em;
  color:rgb(0, 34, 67);
  font-size: 1.7rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  line-height: 1.6;
  letter-spacing: 0.0075em;
}

.logout{
  margin-left: auto;
display: flex;
  align-items: center; 
}